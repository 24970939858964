import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private meta: Meta) {
    this.meta.updateTag({ name: 'description', content: `Lake Highlands metal artist making art for your home or business.` });
    this.meta.updateTag({
      name: 'twitter:card',
      value: `Ruthy's Metal Works. Lake Highlands metal artist making art for your home or business.`
    });
  }

  ngOnInit() {
  }

}
