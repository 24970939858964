import bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { environment } from '../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { HomeComponent } from './pages/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { WorksComponent } from './pages/works/works.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ToastDirective } from './directives/toast.directive';
import { EnlargeOnClickDirective } from './directives/enlarge-on-click.directive';
import { EnglargeComponentComponent } from './components/englarge-component/englarge-component.component';

const bugsnagClient = bugsnag({
  apiKey: environment.bugsnag.api_key,
  appType: 'client',
  appVersion: environment.version,
  notifyReleaseStages: ['production'],
  releaseStage: environment.production ? 'production' : 'local'
});

export function errorHandlerFactory() {
  return new BugsnagErrorHandler(bugsnagClient);
}

@NgModule({
  declarations: [
    AppComponent,
    TopNavComponent,
    HomeComponent,
    FooterComponent,
    WorksComponent,
    ContactComponent,
    ToastDirective,
    EnlargeOnClickDirective,
    EnglargeComponentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [ { provide: ErrorHandler, useFactory: errorHandlerFactory }],
  bootstrap: [AppComponent]
})
export class AppModule { }
