export const environment = {
  production: true,
  version: '2.1.0',
  business: {
    name: 'Ruthy\'s Metal Works',
    email: 'jennifer@ruthysmetalworks.com'
  },
  bugsnag: {
    api_key: 'dcaec8f80e3b00cf4d9145ece7453d45'
  }
};
