/// <reference types="@types/gtag.js" />
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

declare var gtag: Gtag.Gtag;

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor() {}

  public trackEvent(action: string, params: Gtag.EventParams = {}): void {
    if (!environment.production || !gtag) {
      return;
    }
    gtag('event', action, params);
  }
}
