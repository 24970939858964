<div class="top-nav-container">
  <div class="top-nav-logo-container">
    <a routerLink="/">
      <img src="/assets/ruthy_logo.webp" class="top-nav-logo" />
    </a>
  </div>
  <div class="top-nav-links-container">
    <a class="top-nav-link" routerLink="/works" routerLinkActive="link-active">Selected Works</a>
    <a class="top-nav-link" routerLink="/contact" routerLinkActive="link-active">Contact</a>
  </div>
</div>
