import { Directive, Input, HostListener } from '@angular/core';
import { TrackingService } from '../services/tracking.service';
import { StateService } from '../services/state.service';

@Directive({
  selector: '[appEnlargeOnClick]'
})
export class EnlargeOnClickDirective {

  @Input('appEnlargeOnClick') assetUrl: string;

  constructor(private tracker: TrackingService, private state: StateService) { }

  @HostListener('click') onClick() {
    this.tracker.trackEvent('enlargement', { event_label: this.assetUrl });
    this.state.setEnlargedImageUrl(this.assetUrl);
  }
}
