import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  email: string;

  businessName: string;

  year: number;

  constructor() {
    this.email = environment.business.email;
    this.businessName = environment.business.name;
    this.year = (new Date()).getFullYear();
  }

  ngOnInit() {
  }
}
