import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-englarge-component',
  templateUrl: './englarge-component.component.html',
  styleUrls: ['./englarge-component.component.scss']
})
export class EnglargeComponentComponent implements OnInit {

  public url: string;

  constructor(private state: StateService) {
    this.url = '';
    this.state.onEnlargedImageUrl((url: string) => {
      this.url = url;
    });
  }

  ngOnInit(): void {
  }

  public clearEnlarged() {
    this.state.setEnlargedImageUrl('');
  }
}
