import { Injectable } from '@angular/core';

export enum ArtCategory { WALL_ART = 'wall_art', STAND_ALONE = 'stand_alone' }

export enum ArtImageSize { SMALL = 'sm', MEDIUM = 'md', LARGE = 'lg', XLARGE = 'xl' }
export interface IArt {
  url: string;
  hasResizedImages: boolean;
  loadOriginal: boolean;
  title: string;
  description: string;
  category: ArtCategory;
}

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  private art: IArt[] = [];

  constructor() { }

  public async preloadImages(): Promise<void> {
    const artItems = await this.getArt();
    for (const artItem of artItems) {
      if (artItem.loadOriginal) {
        this.createPrefetchLink(artItem.url);
      }
      if (artItem.hasResizedImages) {
        const sizes = [ArtImageSize.SMALL, ArtImageSize.MEDIUM, ArtImageSize.LARGE, ArtImageSize.XLARGE];
        sizes.forEach(size => {
          const url = this.getResizedArtUrl(artItem, size);
          this.createPrefetchLink(url);
        });
      }
    }
    return Promise.resolve();
  }

  public getResizedArtUrl(artItem: IArt, size?: ArtImageSize): string {
    if (!size) {
      return artItem.url;
    }
    const extDotIndex = artItem.url.lastIndexOf('.');

    return `${artItem.url.slice(0, extDotIndex)}_${size}${artItem.url.slice(extDotIndex)}`;
  }

  public async getArt(): Promise<IArt[]> {
    if (this.art.length) {
      return Promise.resolve(this.art);
    }
    this.art = await this.fetchArt();
    return Promise.resolve(this.art);
  }

  private async fetchArt(): Promise<IArt[]> {
    return fetch('/assets/data/art.json')
            .then(response => response.json());
  }

  private createPrefetchLink(url: string): void {
    const link = document.createElement('link');
    link.href = url;
    link.as = 'image';
    link.rel = 'prefetch';
    document.head.appendChild(link);
  }
}
