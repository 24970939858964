<div class="footer-container">
  <div class="footer-info">
    <div class="footer-info-text">
      Contact: <a [href]="'mailto:' + email" target="_blank" class="txt-link">{{ email }}</a>
    </div>
    <div class="footer-info-text">
      Copyright &copy; {{ year }} {{ businessName }}
    </div>
  </div>
  <div class="footer-icons">
    <a href="https://www.facebook.com/ruthysmetalworks/" target="_blank">
      <img class="footer-icon facebook" src="/assets/icons/facebook-icon-sm.png" />
    </a>
    <a href="https://www.instagram.com/ruthysmetalworks/" target="_blank">
      <img class="footer-icon" src="/assets/icons/instagram-icon-sm.png" />
    </a>
  </div>
</div>
