import { Injectable } from '@angular/core';

type EnlargedImageCallback = (url: string) => void;

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private enlargedImageUrl = '';

  private enlargedImageCallbacks: EnlargedImageCallback[] = [];

  constructor() { }

  public onEnlargedImageUrl(callback: EnlargedImageCallback): void {
    this.enlargedImageCallbacks.push(callback);
  }

  public setEnlargedImageUrl(url: string): void {
    this.enlargedImageUrl = url;
    this.enlargedImageCallbacks.forEach(callback => callback(this.enlargedImageUrl));
  }
}
