import { Component } from '@angular/core';
import { MediaService } from './services/media.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private media: MediaService) {
    this.media.preloadImages();
  }
}
